import React from "react"

type TestimonialCardProps = {
  name: string
  message: string
  image: string
  classes: string
}

export const TestimonialCard = ({
  name,
  message,
  image,
  classes,
}: TestimonialCardProps) => {
  return (
    <div
      className={`border-t-8 border-solid border-mainBrand shadow-sm bg-white px-8 py-16 h-full flex flex-col items-center lg:px-12 lg:text-center ${classes}`}
    >
      <div className="w-8 h-8 mb-2 overflow-hidden">
        <img className="w-full h-full" src={image} alt="Client headshot" />
      </div>
      <p
        data-testid="testimonialCardMessage"
        className="flex-grow mb-4 text-md md:text-center xl:text-left lg:text-xl"
      >
        {message}
      </p>
      <p className="mb-1 font-sans text-2xl font-semibold text-black">{name}</p>
      <p className="text-sm font-bold text-textLight">Client</p>
    </div>
  )
}

export default TestimonialCard
